html, body, #root{
  height: 100%;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input, button{
  font-size: 16px !important;
  @media screen and (max-width: 993px) {
    font-size: 14px !important;
  }
}

span, div{
  font-size: 16px;
  @media screen and (max-width: 993px) {
    font-size: 14px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number']{
  -webkit-appearance: none;
  -moz-appearance: textfield;
}

button:disabled {
  background: gray;
  border: 1px solid gray;
  cursor: not-allowed;
}

@font-face {
  font-family: 'Museo Sans Regular';
  src: local('Museo Sans Regular'), url(./fonts/MuseoSans-100.otf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'Museo Sans Medium';
  src: local('Museo Sans Medium'), url(./fonts/MuseoSans-300.otf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Museo Sans SemiBold';
  src: local('Museo Sans SemiBold'), url(./fonts/MuseoSans_500.otf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'Museo Sans Bold';
  src: local('Museo Sans Bold'), url(./fonts/MuseoSans_700.otf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

.ff-museosans-reg{
  font-family: 'Museo Sans Regular';
}
.ff-museosans-med{
  font-family: 'Museo Sans Medium';
}
.ff-museosans-sm{
  font-family: 'Museo Sans SemiBold';
}
.ff-museosans-bold{
  font-family: 'Museo Sans Bold';
}
.font-size-sm{
  font-size: 12px;
}
.font-size-m{
  font-size: 14px;
}
.font-size-lg{
  font-size: 18px;
}
.cursor-pointer{
  cursor: pointer;
}
.cursor-not-allowed{
  cursor: not-allowed;
}
.position-relative{
  position: relative;
}
.position-absolute{
  position: absolute;
}
.m-t-24{
  margin-top: 24px;
}
.flex-1{
  flex: 1;
}
body{
  font-family: 'Museo Sans Regular' !important;
}

