.insurance-field-wrapper{
    width: 620px;
    @media screen and (max-width: 993px) {
        width: 250px;
    }
}
.insurance-field-group{
    margin-top: 24px;  
    & input[type='text']{
        width: 300px;
        height: 56px;
        @media screen and (max-width: 993px) {
            width: 250px;
        }
    }  
    @media screen and (max-width: 993px) {
        flex-direction: column;
    }
}
.provider-plan-info{
    @media screen and (max-width: 993px) {
        flex-direction: column;
    }
}

.insurance-policy-holder-wrapper{
    @media screen and (max-width: 993px) {
        margin-top: 24px;
    }
}
.insurance-lastname-wrapper{
    @media screen and (max-width: 993px) {
        margin-top: 24px;
    }
}

.insurance-gender-wrapper{
    @media screen and (max-width: 993px) {
        margin-top: 24px;
    }
}
.skip-button{
    margin: 24px !important;
}