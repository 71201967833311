.header-wrapper{
  background: linear-gradient(180deg, #313131 0%, #000000 100%);
  padding: 0 40px 40px;
}
.header{
  height: 100px;
  justify-content: space-between;
  > li {
      list-style-type: none;
    > a {
        text-decoration: none;
        color: #000;
    }
  }
}
.logout-icon-wrapper{
  margin-right: 12px;
  cursor: 'pointer';
  width: '24px'; 
  height: '24px';
  @media screen and (max-width: 993px) {
    margin-right: 0;
  }
}