
.service-wrapper{
    margin-top: 24px;
}
.service-heading{
    font-size: 28px;
    margin: 12px;
    @media screen and (max-width: 993px) {
        font-size: 18px !important;
        text-align: center;
    }
}
.service-item-wrap{
    align-items: flex-start;
    width: 200px;
    @media screen and (max-width: 390px) {
        width: 120px;
    }
    & > span{
        text-align: left;
    }
    & > span:nth-child(2){
        color: gray;
    }
}