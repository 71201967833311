.address-box-wrapper{
    justify-content: center;
}
.address-box{
    border: 1px solid #000000;
    min-width: 200px;
    min-height: 200px;
    overflow-y: auto;
    margin: 8px;
    border-radius: 8px;
    font-size: 15px;
    min-height: 175px;
    min-width: 200px;
    padding: 18px;
    font-weight: 700;
    text-transform: uppercase;

    & span{
        margin:4px 0;
        max-width: 200px;
        word-break: break-word;
    }
    @media screen and (max-width:800px) {
        width: 150px;
        min-height: 145px !important;
    }
    @media screen and (max-width:500px) {
        min-width: 100px;
        min-height: 120px !important;
        height: 120px !important;
    }
}

.address-form-fields-wrapper{
    margin-top: 24px;  
    width: 620px;
    justify-content: space-between;
    flex-wrap: wrap;
    @media screen and (max-width:800px) {
        width: 300px;
    }
    & input{
        width: 300px;
        margin-bottom: 16px;
        @media screen and (max-width:800px) {
            width: 150px
        }
    }
    & input:last-child{
        width: 620px;
        @media screen and (max-width:800px) {
            width: 300px;
        }
    }
}
.search-places-input{
    height: 56px;
    width: 100%;
    border: none;
    border-bottom: 1px solid #000000;
    @media screen and (max-width: 993px) {
        height: 40px;
    }
    outline:none;
}
.search-location-wrap{
    width: 350px;
    margin: 24px 0;
    @media screen and (max-width: 993px) {
        width: 280px;
    }
}