@import '../../utils/variables.scss';

.step-wrapper{
  background: $gray-1;
  height: 10px;
  border-radius: 8px;
}
.step{
  background: $primary-red;
  border-radius: 8px;
}