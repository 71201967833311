.dashboard-wrapper{
    margin-top: 24px;
    display: flex;
    flex-direction: column;
}

.category-heading{
    font-size: 28px;
    margin: 24px 0;
    @media screen and (max-width: 993px) {
        font-size: 18px !important;
        text-align: center;
    }
}
.consultation-text{
    font-size: 22px;
    @media screen and (max-width: 993px) {
        font-size: 18px !important;
        text-align: center;
    }
}