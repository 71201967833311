.btn-wrapper{
  background: #000000;
  color: white;
  border-radius: 8px;
  padding: 8px 8px;
  box-shadow: none;
  border: 1px solid #000000;
  font-weight: bold;
  // &:hover{
  //   color: white;
  //   background: black;
  //   border: 1px solid black;
  // }
}

.secondary-btn-wrapper{
  color: #000000;
  background: #ffffff;
  border-radius: 8px;
  padding: 8px 8px;
  box-shadow: none;
  border: 1px solid #000000;
  font-weight: bold;

}
.service-btn-wrapper{
  background: #000000;
  color: #ffffff;
  border: 1px solid #000000;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.4), inset 0px 1px 0px rgba(255, 255, 255, 0.3);
  border-radius: 16px;
  padding: 32px;
  @media screen and (max-width: 993px) {
    padding: 24px;
  }
}
