.call-waiting-wrapper{
    background: linear-gradient(180deg, #494444 0%, #000000 100%);
    width: 100%;
    height: 100%;
    padding: 10px;
}

.waiting-room-header{
    font-size: 28px;
    margin: 32px;
    @media screen and (max-width: 993px) {
        font-size: 18px !important;
        text-align: center;
        margin: 24px;
    }
}
.waiting-room-description{
    font-size: 22px;
    color: #ffffff;
    text-align: center;
    max-width: 800px;
    @media screen and (max-width: 993px) {
        font-size: 14px !important;
        text-align: center;
        max-width: 800px;
    }
    @media screen and (max-width: 800px) {
        max-width: 400px;
    }
}