.welcome-screen-btn-wrapper{
    margin-top: 50px;
    & button{
        margin-right: 20px;
    }
    @media screen and (max-width:993px){
        flex-direction: column;
        margin: 0;
        
        & button{
            margin-right: 0;
        }
        & button:nth-child(2){
            margin-top: 15px;
        }
      }
}