.survey-wrapper{
    margin-top: 24px;
}
.survey-textarea{
    padding: 5px;
    font-size: 20px;
    border-radius: 8px;
    max-height: 300px;
    width: 300px;
    min-height: 150px;
    @media screen and (max-width: 993px) {
        font-size: 14px; 
    }
}
.question-text{
    font-size: 20px;
    @media screen and (max-width: 993px) {
        font-size: 16px; 
    }
}