@import '../../../../utils/variables.scss';

.ty-wrapper{
    margin-top: 24px;
}
.ty-steps-wrapper{
    width: 520px;
    max-width: 620px;
    margin-bottom: 24px;
   
}
.ty-step{
    padding: 24px 80px;
    height: 150px;
    & div{
        max-width: 350px;
    }
}
.ty-item-width-500{
    width: 500px
}
.ty-step-circle{
    background: $primary-red;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    top: 32px;
    left: 40px;
    z-index:1;
}
.ty-step-line{
    width: 1px;
    height: 150px;
    top: 32px;
    left: 45px;
    z-index:0;
}
.what-comes-next{
    @media screen and (max-width: 993px){
        text-align: center;
    }
}