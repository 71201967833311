.twilio-video-wrapper{
    position: fixed;
    z-index:101;
    top:0;
    width: 100%;
    height: 100%;
    background: #ffffff;
}

.localParticipant{
    position: absolute;
    right: 0;
    z-index: 1001;
    & .video {
       > video{
        width: 200px;
        height: 200px;
       } 
    }
    border: 1px solid white;
}
.remote-participants-wrapper{
    position: relative;
    display: flex;
    justify-content: center;
    background: black;
}
.controls-wrapper{
    position: absolute;
    bottom: 20px;
    justify-content: center;
}
.video-controls{
    background: #f2f2fa;
    padding: 10px;
    margin:3px;
}
.mute-controls{
    background: #f2f2fa;
    padding: 10px;
    margin:3px;
}
.end-call-controls{
    background: #D2042D;
    padding: 10px;
    margin:3px;
}
.identity{
    color: white;
    font-weight: bold;
}