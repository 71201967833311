.profile-form-wrapper{
    margin-top: 24px;  
}
.profile-form{
    margin-top: 24px;  
    width: 620px;
    justify-content: space-between;
    flex-wrap: wrap;
    @media screen and (max-width:800px) {
        width: 100%;
        flex-direction: column;
        align-items: center;
    }
    & input{
        width: 300px;
        margin-bottom: 16px;
    }
    & .dd-wrapper{
        width: 300px;
    }
}
.gender-ethnicity-wrapper{
    @media screen and (max-width:800px) {
        width: 100%;
        flex-direction: column;
        align-items: center;
    }
    & .dd-wrapper{
        margin-bottom: 16px;
    }
}
.picker-wrapper{
    & input{
        height: 56px;
        border-radius: 8px;
        border: 1px solid #000000;
        width: 300px !important;
        @media screen and (max-width:993px) {
            height: 40px;
        }
        @media screen and (max-width:800px) {
            width: 300px !important;
        }

    }
}
.email-wrapper{
    & input{
        width: 620px !important; 
        @media screen and (max-width:800px) {
            width: 300px !important;
        }
    }
}
.react-datepicker__input-container input{
    border: none;
    border-bottom: 1px solid #000000;
    border-radius: 0;
    outline: none;
}