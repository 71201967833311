@import '../../utils/variables.scss';
// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

a{
  text-decoration: none;
  color: #000000;
  :hover{
    background: transparent;
  }
}

