.loader-wrapper{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    opacity: 0.8;
}
.center {
  height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
}
.wave {
  width: 5px;
  height: 100px;
  background: linear-gradient(45deg, rgb(233, 128, 144), #fff);
  margin: 10px;
  animation: wave 1s linear infinite;
  border-radius: 20px;
  @media screen and (max-width: 993px) {
    height: 70px;
  }
}
.wave:nth-child(2) {
  animation-delay: 0.1s;
}
.wave:nth-child(3) {
  animation-delay: 0.2s;
}
.wave:nth-child(4) {
  animation-delay: 0.3s;
}
.wave:nth-child(5) {
  animation-delay: 0.4s;
}
.wave:nth-child(6) {
  animation-delay: 0.5s;
}
.wave:nth-child(7) {
  animation-delay: 0.6s;
}
.wave:nth-child(8) {
  animation-delay: 0.7s;
}
.wave:nth-child(9) {
  animation-delay: 0.8s;
}
.wave:nth-child(10) {
  animation-delay: 0.9s;
}

@keyframes wave {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

