.appointment-page-heading{
    font-size: 22px;
    text-align: center;
    margin: 24px;
    @media screen and (max-width: 993px){
        font-size: 16px;
    }
}
.appointment-page-description{
    font-size: 16px;
    text-align: center;
    margin-top: 32px;

    @media screen and (max-width: 993px){
        font-size: 12px;
        margin-top: 24px;
    }
}
.appointment-field-wrapper{
    margin-top: 24px;  
    width: 720px;
    justify-content: space-between;
    flex-wrap: wrap;
    & input{
        width: 350px !important;
        margin-bottom: 16px;
       
    }
    @media screen and (max-width: 993px){
        width: 360px;
    }

}
.date-picker-wrapper-appointment{
    & input{
        height: 56px;
        @media screen and (max-width: 993px){
            height: 40px;
        }
    }
}