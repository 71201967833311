.verification-form-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.verification-form{
    width: 620px;
    justify-content: space-between;
    flex-wrap: wrap;
    @media (max-width: 993px){
        width: 100%;
    }
    & input{
        width: 300px;
        margin-bottom: 16px;
        @media (max-width: 993px){
            height: 40px;
            padding: 4px;
            width: 100%;
        }
    }
}
.otp-button-wrapper{
    width: 100%;
    text-align: center;
    margin: 16px 0;

    & button{
        width: 250px;
        @media screen and (max-width: 993px){
            width: 150px !important;
        }
    }
}
.country-code{
    width: 80px!important;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-right: 24px;
    @media screen and (max-width:993px){
        width: 60px!important;
        margin-right: 16px;
    }
  }
  .verification-screen-title{
      max-width: 500px;
      @media screen and (max-width:700px){
        max-width: 200px;
    }
  }