.submit-btn-wrapper{
    margin-top:24px;
}
.user-agreement-heading{
    font-size: 28px;
    text-align: center;
    margin-bottom: 24px;
    @media screen and (max-width: 993px) {
        font-size: 18px !important;
        margin-bottom: 16px;
    }
}
.user-agreement-description{
    margin: 24px 0;
    text-align: center;
    font-size: 18px;
    @media screen and (max-width: 993px) {
        margin: 16px 0;
        font-size: 14px;
    }
}
.agreement-link{
    color: blue;
    margin: 0 4px;
}