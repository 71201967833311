.input-wrapper{
  border: none;
  border-bottom: 1px solid #000000;
  outline: none;
  height: 56px;
  @media screen and (max-width:993px){
    height: 40px;
    padding: 4px;
  }
  &:disabled{
    background-color: #f5f5f5;
    cursor: not-allowed;
  }
}
.error-span{
  color:red;
  font-size: 12px;
}
